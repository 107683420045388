<template>
    <div class="container-geral">
        <div class="container-tarefa2">
            <div class="produtos2">
                <router-link
                    v-for="(produto, keyProduto) in tarefas"
                    :key="keyProduto"
                    class="produto2"
                    :class="{ 'produto2-retirada': isRetirada(produto.tipo_tarefa) }"
                    :to="{
                        name: 'verificacao.produtos',
                        params: {
                            codigo: produto.codigo_de_barras,
                            validade: produto.data_validade,
                        },
                    }"
                >
                    <div class="container-img">
                        <img
                            :src="
                                'https://147266017877-app-images.s3.sa-east-1.amazonaws.com/' +
                                produto.codigo_de_barras +
                                '.webp'
                            "
                            @error="replaceByDefault"
                            :alt="produto.descricao"
                        />
                    </div>
                    <div class="nome-validade">
                        <h2 :class="{ 'nome-validade-retirada': isRetirada(produto.tipo_tarefa) }">
                            {{ produto.descricao }}
                        </h2>
                        <h3
                            class="validade-tarefa"
                            :class="{ 'validade-tarefa-retirada': isRetirada(produto.tipo_tarefa) }"
                        >
                            {{ convertDate(produto.data_validade) }}
                        </h3>
                    </div>
                    <div class="btn-edit">
                        <img src="../assets/img/edit.svg" alt="botão para editar" />
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import img from '../assets/img/broken-image.svg';

export default {
    data() {
        return {
            tarefas: null,
            isTarefa: false,
        };
    },
    computed: {
        getTarefas() {
            return this.$store.getters.getTarefas;
        },
    },
    created() {
        this.tarefas = this.getTarefas;

        if (Object.keys(this.tarefas).length === 0) {
            this.$router.go(-1);
        }
    },
    methods: {
        convertDate(date) {
            if (date != '') {
                let dateSplit = date.split('-');
                let newDate = dateSplit[2] + '/' + dateSplit[1] + '/' + dateSplit[0];
                return newDate;
            } else {
                return '';
            }
        },

        categoriaFormatada(categoria) {
            let findDot = categoria.indexOf('.');

            if (findDot < 0) {
                return categoria;
            } else {
                let categoriaSemNumero = categoria.split('.');
                return categoriaSemNumero[1];
            }
        },

        replaceByDefault(e) {
            e.target.src = img;
        },

        isRetirada(value) {
            return value == 'Retirada' ? true : false;
        },
    },
};
</script>

<style scoped>
.container-geral {
    margin-top: 66px;
    margin-bottom: 86px;
}

.container-tarefa2 {
    margin: 15px 10px;
    border-radius: 5px;
    /* box-shadow: 0 0 5px var(--cinza-escuro); */
}

.produto2 {
    display: grid;
    grid-template-columns: 1fr 3fr;
    padding: 20px 10px;
    background-color: #fff;
    box-shadow: -6px 7px 0px 0px var(--verde);
    border: 1px solid #d3d3d3;
    margin-bottom: 20px;
    border-radius: 10px;
    position: relative;
}

.produto2-retirada {
    box-shadow: -6px 7px 0px 0px var(--vermelho);
}

.btn-edit {
    position: absolute;
    right: 10px;
    bottom: 15px;
    padding: 5px;
    border: 1px solid #dbdbdb;
    display: flex;
    border-radius: 4px;
    background-color: #fff;
}

.btn-edit img {
    width: 20px;
    height: 20px;
}

.container-img {
    display: flex;
    place-items: center;
}

.container-img img {
    width: 70px;
    height: 70px;
}

.nome-validade {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.nome-validade h2 {
    font-size: 14px;
    text-transform: uppercase;
    overflow: hidden;
    max-width: 23ch;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #484848;
}

.nome-validade-retirada {
    color: var(--vermelho) !important;
}

.validade-tarefa {
    margin-top: 5px;
    font-size: 12px;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #484848;
}

.validade-tarefa-retirada {
    color: var(--vermelho) !important;
}

h2.produto-alerta {
    color: #ef6969;
    font-weight: bold;
}

.corredores-retirada {
    background-color: #ef6969;
}

.title-retirada {
    color: #fff;
}

.validade-retirada {
    color: #ef6969;
}

@media (max-width: 319px) {
    .corredores-title {
        font-size: 14px;
        max-width: 24ch;
    }

    .nome-validade h2 {
        max-width: 17ch;
    }
}
</style>
